@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500");
@import url("https://fonts.googleapis.com/css?family=Nunito:300i,400");
@import url("https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css");

body {
  font-family: Roboto, sans-serif;
}

h2 {
  font-size: 28px;
  font-style: italic;
  font-family: 'Nunito', sans-serif;
  margin-bottom: 12px;
  font-weight: 400;
}
